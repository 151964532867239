<template>
    <v-container class="ma-5 mx-auto" fluid>
        <v-row class="mx-auto justify-center">
            <v-avatar :size="avatar_size" class="avatar">
                <v-img lazy-src :src="profile.avatar_url"></v-img>
            </v-avatar>
        </v-row>
        <v-row align="center" justify="center" class="mt-10">
            <h2 class="text-center text-uppercase font-weight-thin">
                {{ profile.username }}
            </h2>
        </v-row>
        <v-row align="center" justify="center" class="mt-6">
            <p class="text-center text-uppercase font-weight-thin body-1">
                {{ profile.email }}
            </p>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'ProfileCard',
        props: {
            profile: {
                required: true,
            },
            avatar_size: {
                type: String,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .text-center {
        font-size: 2em;
    }
    .avatar {
        border: 2px solid white;
        filter: drop-shadow(0px 0px 10px #000);
        box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
    }
</style>
