<template>
    <v-container fill-height fluid class="mx-auto mt-10">
        <transition name="fade" mode="out-in">
            <v-btn
                v-scroll="onScroll"
                v-show="fab"
                fab
                dark
                fixed
                bottom
                right
                color="rgba(10,10,10,.8)"
                @click="toTop"
            >
                <v-icon large>mdi-arrow-up</v-icon>
            </v-btn>
        </transition>
        <v-card
            class="mx-auto"
            color="rgba(0,0,0,0)"
            flat
            min-height="80%"
            min-width="80%"
            max-width="80%"
            dark
        >
            <v-container>
                <v-row justify="space-around" class="pa-md-2">
                    <v-col cols="12" md="4">
                        <ProfileCard
                            :profile="owner"
                            :avatar_size="avatarSize"
                        />
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-card
                            class="pa-md-2"
                            width="100%"
                            color="rgb(0, 0, 0, 0.2)"
                            flat
                        >
                            <h1 class="text-uppercase font-weight-thin">
                                Philosophy
                            </h1>
                            <v-sheet
                                color="rgba(0,0,0,.8)"
                                class="body-1 font-weight-thin"
                            >
                                <p>
                                    At one point or another in our own academic
                                    careers, we've all wished that we had
                                    someone there to answer a homework question
                                    or even spark some inspriation in us while
                                    learning. I hope to be that person for those
                                    seeking help on something like a homework
                                    assignment or seeking advice for future
                                    endeavors.
                                </p>
                            </v-sheet>
                        </v-card>

                        <v-divider></v-divider>

                        <v-card
                            class="pa-md-2"
                            width="100%"
                            color="rgb(0, 0, 0, 0.2)"
                            flat
                        >
                            <h1 class="text-uppercase font-weight-thin">
                                Education
                            </h1>
                            <v-sheet
                                color="rgba(0,0,0,.8)"
                                class="body-1"
                                style="margin-left: -15px"
                            >
                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-img
                                            contain
                                            src="https://i.imgur.com/NdP77wM.png"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Duke University (2019)
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            Dual BSE in Biomedical and
                                            Electrical/Computer Engineering
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle
                                            class="font-italic"
                                        >
                                            Graduation with Distinction
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-img
                                            src="https://i.imgur.com/fxbitJp.png"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            George Washington University School
                                            of Medicine (2025)
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            MD Candidate
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-sheet>
                        </v-card>

                        <v-card
                            class="pa-md-2"
                            width="100%"
                            color="rgb(0, 0, 0, 0.2)"
                            flat
                        >
                            <h1 class="text-uppercase font-weight-thin">
                                Teaching Experience
                            </h1>
                            <v-sheet
                                color="rgba(0,0,0,.8)"
                                class="body-1"
                                style="margin-left: -15px"
                            >
                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-chip</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Electrical and Computer Engineering
                                            TA @ Duke
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            3+ years experience teaching the
                                            following classes: Circuits,
                                            Semiconductors, Signals and Systems.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-apache-kafka</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Computer Science TA @ Duke
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            2+ Years experience teaching
                                            following classes: Data Structures
                                            and Algorithms.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>
                                            mdi-calculator-variant-outline
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Private and Group Tutor
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            5+ years experience tutoring
                                            students of ages 5-22.
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-wrap">
                                            Topics range from basic programming
                                            to multivariable calculus.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-sheet>

                            <h1 class="text-uppercase font-weight-thin">
                                Work Experience
                            </h1>
                            <v-sheet
                                color="rgba(0,0,0,.8)"
                                class="body-1"
                                style="margin-left: -15px"
                            >
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-img
                                            src="https://i.imgur.com/lUi9d3b.png"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <!--                                     <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-atom-variant</v-icon>
                                    </v-list-item-avatar> -->
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Research Fellow @ NIH/NIBIB
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            Developing image processing and ML
                                            algorithms for new paradigms of
                                            fluorescence microscopy.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-img
                                            src="https://i.imgur.com/gULGK9h.png"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <!--                                     <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-atom-variant</v-icon>
                                    </v-list-item-avatar> -->
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Research Fellow @ Duke
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            Developed molecular tools for
                                            neuroscience research such as
                                            genetically-encoded voltage sensors.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-img
                                            src="https://i.imgur.com/KMUse4i.png"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <!--                                     <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-atom-variant</v-icon>
                                    </v-list-item-avatar> -->
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Bioengineering Intern @ MedImmune
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            Determined the efficiacy of certain
                                            anti-cancer drugs.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-img
                                            src="https://i.imgur.com/GgPEP0U.png"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <!--                                     <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-atom-variant</v-icon>
                                    </v-list-item-avatar> -->
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Software Engineering Intern @
                                            Koolspan
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            Mobile App and Test-bench
                                            Development.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-sheet>

                            <h1 class="text-uppercase font-weight-thin">
                                Awards
                            </h1>
                            <v-sheet
                                color="rgba(0,0,0,.8)"
                                style="margin-left: -15px"
                            >
                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>
                                            mdi-trophy-outline
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <!--                                     <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-atom-variant</v-icon>
                                    </v-list-item-avatar> -->
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Duke Undergraduate Teaching
                                            Assistant Award
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>
                                            mdi-trophy-outline
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <!--                                     <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-atom-variant</v-icon>
                                    </v-list-item-avatar> -->
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            Edward D. "Ned" Light Memorial Award
                                            for Distinguished Undergraduate
                                            Research
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-sheet>

                            <h1 class="text-uppercase font-weight-thin">
                                Publications
                            </h1>
                            <v-sheet
                                color="rgba(0,0,0,.8)"
                                style="margin-left: -15px"
                            >
                                <v-list-item
                                    href="https://www.osapublishing.org/boe/fulltext.cfm?uri=boe-10-12-6595&id=423662"
                                    target="_blank"
                                >
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-book</v-icon>
                                    </v-list-item-avatar>
                                    <!--                                     <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-atom-variant</v-icon>
                                    </v-list-item-avatar> -->
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="text-wrap">
                                            Hybrid light-sheet and light-field
                                            microscope for high resolution and
                                            large volume neuroimaging.
                                            Biomedical Optics Express 10 (2019).
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    href="https://pubs.rsc.org/en/content/articlelanding/2021/lc/d0lc01233e/unauth#!divAbstract"
                                    target="_blank"
                                >
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-book</v-icon>
                                    </v-list-item-avatar>
                                    <!--                                     <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-atom-variant</v-icon>
                                    </v-list-item-avatar> -->
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="text-wrap">
                                            A polymer index-matched to water
                                            enables diverse applications in
                                            fluorescence microscopy. Lab on a
                                            Chip (2021).
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-sheet>

                            <h1 class="text-uppercase font-weight-thin">
                                Interests and Hobbies
                            </h1>
                            <v-sheet
                                color="rgba(0,0,0,.8)"
                                style="margin-left: -15px"
                            >
                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-swim</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            I've been a swimmer since 6 and have
                                            recently taken up running.
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            Speaking of teaching, I've also
                                            taught swimming for 3+ years.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-music</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            I play the piano and like listening
                                            to EDM/DnB.
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            Classical music can only keep you
                                            awake for so long while studying,
                                            you know.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>
                                            mdi-gamepad-variant
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            I play a lot of different games, but
                                            won't go into detail.
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            No, League of Legends is not one of
                                            them.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-avatar rounded="0">
                                        <v-icon large>mdi-xml</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">
                                            I work on coding projects that I
                                            think are fun in my free time.
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            It also helps me to learn all of the
                                            new programming tools out there.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-sheet>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
    import ProfileCard from '@/components/ProfileCard.vue';

    export default {
        data() {
            return {
                fab: false,
                owner: {
                    username: 'Stephen Xu',
                    avatar_url: 'https://i.imgur.com/uGgV3Sw.jpeg',
                    email: 'stephen.xu@catalyst-tutors.com',
                    link: '',
                    description: '',
                },
            };
        },
        components: {
            ProfileCard,
        },
        methods: {
            onScroll(e) {
                if (typeof window === 'undefined') return;
                const top = window.pageYOffset || e.target.scrollTop || 0;
                this.fab = top > 100;
            },
            toTop() {
                this.$vuetify.goTo(0);
            },
        },
        computed: {
            avatarSize() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return '50%';
                    case 'sm':
                        return '50%';
                    case 'md':
                        return '100%';
                    case 'lg':
                        return '100%';
                    case 'xl':
                        return '100%';
                }
                return '50em';
            },
        },
    };
</script>

<style lang="scss" scoped>
    * {
        line-height: 2em;
        font-weight: 100;
    }
    h1 {
        color: #aaa;
    }
    p {
        font-size: 20px;
    }
    ::v-deep .v-list-item__title {
        font-size: 20px;
    }

    ::v-deep .v-list-item__subtitle {
        font-size: 16px;
    }
</style>
